<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> Exceção
      </div>
      <div class="text-500 mb-5">Exceção</div>

      <div class="card">
        <div
          class="
            flex flex-column
            mb-4
            md:flex-row md:justify-content-between md:align-items-center
          "
        >
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
            <Calendar
              id="dataInicial"
              class="flex py-3"
              v-model="form.data"
              dateFormat="dd/mm/yy"
              :showIcon="true"
              placeholder="Data Inicial"
            />
          </div>
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
            <InputText
              id="nome"
              placeholder="Resumo"
              v-model.trim="form.resumo"
              autofocus
              autocomplete="off"
            />
          </div>
        </div>

        <Toast />

        <DataTable
          :value="form.horarios"
          :row-hover="true"
          editMode="cell"
          responsiveLayout="scroll"
          rowGroupMode="subheader"
          groupRowsBy="seqPeriodo"
          sortField="seqPeriodo"
        >
          <template #empty> Nenhum registro encontrado. </template>

          <template #loading> Carregando registros. Aguarde ... </template>

          <template #groupheader="slotProps">
            <span class="image-text"
              >Período {{ slotProps.data.seqPeriodo }}</span
            >
          </template>

          <Column field="seqPeriodo" header="Período"></Column>

          <Column field="description">
            <template #body="slotProps">
              <b>{{ slotProps.data.description }}</b>
            </template>
          </Column>

          <Column header="Hora inicial">
            <template #body="slotProps">
              <InputMask
                class="p-inputtext-sm"
                :style="styleInputTable"
                v-model="slotProps.data.horarioInicial"
                mask="99:99"
              />
            </template>
          </Column>

          <Column header="Hora final">
            <template #body="slotProps">
              <InputMask
                class="p-inputtext-sm"
                :style="styleInputTable"
                v-model="slotProps.data.horarioFinal"
                mask="99:99"
              />
            </template>
          </Column>

          <Column header="Agendamentos Simultâneos">
            <template #body="slotProps">
              <InputMask
                class="p-inputtext-sm"
                :style="styleInputTable"
                v-model="slotProps.data.atendimentosSimultaneos"
                mask="9?9"
              />
            </template>
          </Column>
        </DataTable>

        <div class="flex mt-5">
          <Button
            label="Novo período"
            icon="pi pi-plus"
            class="p-button-text w-3"
            @click="adicionarPeriodo()"
          />
        </div>

        <div class="flex mt-5">
          <Button
            label="Salvar"
            icon="pi pi-check"
            class="p-button-primary w-6"
            @click="save"
          />
          <Button
            label="Voltar"
            icon="pi pi-times"
            class="p-button-text w-3 ml-auto"
            @click="$router.go(-1)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AreaMedicaService from "../../services/AreaMedicaService";

export default {
  data() {
    return {
      form: {
        horarios: [],
      },
      submitted: false,
      styleInputTable: 'width: 60px'
    };
  },
  mounted() {
    this.$service = new AreaMedicaService(
      `/agendas/${this.$route.params.id}/excecoes`
    );
    this.load();
  },
  methods: {
    async load() {
      if (this.$route.params.idExcecao) {
        const { data } = await this.$service.findById(
          this.$route.params.idExcecao
        );
        this.form = data;
      }
    },
    adicionarPeriodo() {
      const lastSeqPeriodo = Math.max(...this.form.horarios.map(h => h.seqPeriodo).concat(0))
      this.form.horarios.push({ seqPeriodo: lastSeqPeriodo + 1 })
    },
    removerStringVazias() {
      this.form.horarios?.forEach(horario => {
        horario.horarioInicial = horario.horarioInicial || null
        horario.horarioFinal = horario.horarioFinal || null
        horario.tempoAtendimento = horario.tempoAtendimento || null
        horario.atendimentosSimultaneos = horario.atendimentosSimultaneos || null
      })
    },
    async save() {
      this.submitted = true;
      try {
        this.removerStringVazias()
        await this.$service.save(this.form);
        this.$toast.add({
          severity: "success",
          summary: "Salvo com sucesso",
          life: 3000,
        });
        this.$router.go(-1);
      } catch (err) {
        this.$toast.add({
          severity: "error",
          summary: "Problemas ao salvar informações!",
          life: 3000,
        });
      }
    },
  },
};
</script>
